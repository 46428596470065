'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../types/wantlist/index.js');

class Ledger {
  constructor(peerId) {
    this.partner = peerId;
    this.wantlist = new index.Wantlist();
    this.exchangeCount = 0;
    this.sentToPeer = new Map();
    this.accounting = {
      bytesSent: 0,
      bytesRecv: 0
    };
  }
  sentBytes(n) {
    this.exchangeCount++;
    this.lastExchange = new Date().getTime();
    this.accounting.bytesSent += n;
  }
  receivedBytes(n) {
    this.exchangeCount++;
    this.lastExchange = new Date().getTime();
    this.accounting.bytesRecv += n;
  }
  wants(cid, priority, wantType) {
    this.wantlist.add(cid, priority, wantType);
  }
  cancelWant(cid) {
    this.wantlist.remove(cid);
  }
  wantlistContains(cid) {
    return this.wantlist.get(cid);
  }
  debtRatio() {
    return this.accounting.bytesSent / (this.accounting.bytesRecv + 1);
  }
}

exports.Ledger = Ledger;
